const sliderIndexCat = () => new Swiper('.index-cat-tabs__tab-content .swiper-container', {
    observer: true,
    slidesPerView: 1,
    spaceBetween: 20,
    loop: 1,
    breakpoints: {
        1500: {
            slidesPerView: 5,
            spaceBetween: 30,
        },
        1400: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        1300: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        1200: {
            slidesPerView: 3.2,
            spaceBetween: 15,
        },

        992: {
            slidesPerView: 3.2,
            spaceBetween: 15,
        },

        768: {
            slidesPerView: 2.3,
            spaceBetween: 10,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        500: {
            slidesPerView: 1.5,
            spaceBetween: 5,

        },
        300: {
            slidesPerView: 1.1,
        },
    },
});
const swiper_cart_tumb = new Swiper('.mySwiper', {
    spaceBetween: 10,
    slidesPerView: 4,
    simulateTouch: false,

    freeMode: true,
    watchSlidesProgress: true,
});
const swiper2 = new Swiper('.mySwiper2', {
    slidesPerView: 'auto',
    spaceBetween: 1000,
    autoHeight: true,
    simulateTouch: false,
    navigation: {
        nextEl: '.cart__register .cart_swiper-button-next',
        prevEl: '.cart__register .cart_swiper-button-before',
    },
    thumbs: {
        swiper: swiper_cart_tumb,
    },
});
$(".mySwiper2").ready(function () {
    const params = new URLSearchParams(window.location.search);
    if (params.has('step')) {
        if (params.get('step') == 2) {
            // const swiper_cart = document.querySelector('.mySwiper2').swiper;
            // swiper_cart.slideNext();
            setTimeout(function () {
                $('.cart_swiper-button-next').trigger('click');
            }, 500);
        }
    }
})
// eslint-disable-next-line no-undef
$(function () {
    tabInit();
    // $('.single-product__pro-base-img img')
    // eslint-disable-next-line no-undef
    $('a').click(function (event) {
        const scrollOffset = 0;

        if ($(this).is('a[href^="#"]') && $(this).attr('href').length >= 2) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top + scrollOffset,
            }, 1000);
            return false;
        }
    });
    // eslint-disable-next-line no-undef
    $(document).on('click', 'a', function () {
        const scrollOffset = 0;

        // eslint-disable-next-line no-undef
        if ($(this).is('a[href^="#"]') && $(this).attr('href').length >= 2) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top + scrollOffset,
            }, 1000);
            return false;
        }
    });

    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: 'top-end',
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     onOpen: (toast) => {
    //         toast.addEventListener('mouseenter', Swal.stopTimer);
    //         toast.addEventListener('mouseleave', Swal.resumeTimer);
    //     },
    // });
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });
    if ($('.single-blog').length) {
        $('.single-blog__copy i').on('click', function () {
            const text = $(this).siblings('.single-blog__copy-texts').find('em').text();
            Toast.fire({
                icon: 'success',
                title: 'لینک با موفقیت کپی شد',
                footer: text,

            });
        });
    }

    // socials-footer
    $('.site-footer__socials ul li').hover(function () {
        $(this).find('a').addClass('active');
        $(this).siblings().find('a').removeClass('active');
    });
    if (screen.width <= 992) {
        $('.site-footer__socials ul li').find('a').removeClass('active');
        $('.site-footer__socials ul li').hover(function () {
            $(this).find('a').removeClass('active');
        });
    }
    $('.index-about-us__over-social a').hover(function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
    });

    //focos search

    $('.site-header__bottom-l form input').on('click', function (e) {
        e.stopPropagation();
        $(this).css({'background-color': 'transparent', color: '#81706c', opacity: '1'});
        $('.site-header__bottom-l form').css({'background-color': '#faf9f9', 'box-shadow': 'none'});
        $('.site-header__bottom-l form button').css('background-color', 'transparent');
        $('.site-header__bottom-l form button i').css('color', ' #81706c');
    });

    $('.site-header__bottom-l form button').on('click', function (e) {
        e.stopPropagation();
    });

    $('body').on('click', function (e) {
        e.stopPropagation();
        $('.site-header__bottom-l form').css({
            'background-color': '#ffffff',
            'box-shadow': ' 3px 3px 6px rgba(190, 178, 175, 0.14)',
        });
        $('.site-header__bottom-l form button i').css('color', 'rgba(129, 112, 108, 0.8)');
    });

    // end focos search

    //focos login

    $('.site-header__after-login-user').on('click', function (e) {
        e.stopPropagation();
        $(this).css({'background-color': '#faf9f9', 'box-shadow': 'none', color: '#81706c'});
        $('.site-header__after-login-user i').css({color: '#81706c'});
    });

    $('body').on('click', function (e) {
        e.stopPropagation();
        $('.site-header__after-login-user').css({
            'background-color': '#ffffff',
            'box-shadow': ' 3px 3px 6px rgba(190, 178, 175, 0.14)',
        });

        $('.site-header__after-login-user i').css('color', '#00a651');

        $('.site-header__login-list').slideUp();
    });

    $('.site-header__login-list').on('click', function (e) {
        e.stopPropagation();
    });
    //end focos login

    // menu-responsive
    $('body').on('click', function () {
        $('.mega-menu-site-responsive').removeClass('active');
        $(this).removeClass('overflow-none');
    });

    $('.mega-menu-responsive').on('click', function (e) {
        e.stopPropagation();
        $('.mega-menu-site-responsive').toggleClass('active');
        $('.mask-shadow').toggleClass('active');
        $('body').toggleClass('overflow-none');
    });

    $('.mega-menu-site-responsive').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parent().toggleClass('active');
        $(this).siblings('ul').slideToggle(500);
    });

    $('.mega-menu-site-responsive__header').on('click', function (e) {
        e.stopPropagation();
    });

    $('#res-menu-body').on('click', function (e) {
        e.stopPropagation();
    });

    $('.mega-menu-site-responsive__header .close').on('click', function () {
        $('.mega-menu-site-responsive').toggleClass('active');
        $('.mask-shadow').toggleClass('active');
        $('body').toggleClass('overflow-none');
    });

    $('.mask-shadow').on('click', function () {
        $('.mega-menu-site-responsive').toggleClass('active');
        $(this).toggleClass('active');
    });
    $('.mega-menu-site-responsive__body .has-children').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active').find('ul').slideToggle();
    });

    //end-menu-responsive
    // selectize
    $('select:not(".trade-select--download select")').selectize();
    // hedare-slider
    new Swiper('.site-header__top-tipp-out .swiper-container', {
        slidesPerView: 1,
        autoplay: true,
        // spaceBetween: 31,
    });

    // go-top
    if (('.site-footer').length) {
        $('.site-footer__go-top').click(function (event) {
            const scrollOffset = 0;
            if ($(this).attr('href').length >= 1) {
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top + scrollOffset,
                }, 1000);
                return false;
            }
        });
        $(window).on('scroll', function (e) {
            if ($(window).scrollTop() > 400) {
                $('.site-footer__go-top').addClass('active');
            } else {
                $('.site-footer__go-top').removeClass('active');
            }
        });
    }

    //toggle sms login
    $('.toggle-form').on('click', function () {
        $(this).parents('.login-form').slideUp();
        $(this).parents('.login-form').siblings('.login-form').slideDown();
    });

    // index-slider__info
    // var info_swiper = new Swiper('.index-slider__info .swiper-container', {
    //     slidesPerView: 1,
    //     effect: 'fade',
    //     fadeEffect: {
    //         crossFade: true
    //     },
    // });
    // // index-slider__info-image
    // var image_swiper = new Swiper('.index-slider__info-image .swiper-container', {
    //     slidesPerView: 1,
    //     effect: 'fade',
    //     fadeEffect: {
    //         crossFade: true
    //     },
    // });
    // // index-slider__info-desc
    // var desc_swiper = new Swiper('.index-slider__info-desc .swiper-container', {
    //     slidesPerView: 1,
    //     pagination: {
    //         el: '.swiper-pagination',
    //         type: 'bullets',
    //         clickable: true,
    //     },
    //     effect: 'fade',
    //     fadeEffect: {
    //         crossFade: true
    //     },
    //
    // });

    // info_swiper.controller.control = image_swiper;
    // image_swiper.controller.control = info_swiper;
    // desc_swiper.controller.control = image_swiper;
    // image_swiper.controller.control = desc_swiper;
    // desc_swiper.controller.control = info_swiper;
    // info_swiper.controller.control = desc_swiper;

    // index-cat-tabs__tab-content
    if ($('.index-cat-tabs').length) {
        sliderIndexCat();
        $('.index-cat-tabs__tab-ttl').on('click', function () {
            sliderIndexCat();
        });
    }
    // index-about-us__user-slide
    new Swiper('.index-about-us__user-slide .swiper-container', {
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

    });
    // big-video-index
    // $('.index-about-us__over').on('click', function () {
    //
    //     var video = $(this).parents('.index-about-us__video').find('.video').get(0);
    //     if (video.paused) {
    //         video.play();
    //
    //         $(this).parent('.index-about-us__video').addClass('active');
    //
    //     } else {
    //         video.pause();
    //         // $(this).removeClass('active');
    //         $(this).parent('.index-about-us__video').removeClass('active');
    //     }
    // });

    if ($('.index-about-us__video').length) {
        $('.index-about-us__over').on('click', function () {
            $('.index-about-us__video-inset video').get(0).play();
            $(this).removeClass('playing');
            $(this).addClass('playing');
        });
    }

    // $('.index-about-us__video-inset video').on('pause', function () {
    //     $('.index-about-us__video').removeClass('playing');
    //
    // });

    // $('.index-about-us__video .index-about-us__video-inset').on('click', function (e) {
    //     var video = $(this).find('.video').get(0);
    //     console.log(video);
    //     if (video.paused) {
    //         video.play();
    //         $(this).siblings('.index-about-us__over').addClass('active');
    //         $(this).parent().addClass('active');
    //
    //     } else {
    //         video.pause();
    //         $(this).siblings('.index-about-us__over').removeClass('active');
    //         $(this).parent().removeClass('active');
    //     }
    //     console.log(6465);
    // });
    // end-big-video-index
    // our-customers-index
    new Swiper('.our-customers .swiper-container', {
        slidesPerView: 7,
        spaceBetween: 31,
        loop: true,
        autoplay: {
            delay: 1000,
        },

        pagination: {
            el: '.our-customers .swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
        },
        breakpoints: {
            1500: {
                slidesPerView: 7,
                spaceBetween: 31,
            },
            1400: {
                slidesPerView: 5.7,
                spaceBetween: 15,
            },
            1300: {
                slidesPerView: 5.5,
                spaceBetween: 15,
            },
            1200: {
                slidesPerView: 4.7,
                spaceBetween: 15,
            },

            768: {
                slidesPerView: 4.2,
                spaceBetween: 5,
            },
            576: {
                slidesPerView: 2.7,
                spaceBetween: 5,
            },
            500: {
                slidesPerView: 2.1,
                spaceBetween: 10,

            },
            400: {
                slidesPerView: 1.9,
                spaceBetween: 5,

            },
            300: {
                slidesPerView: 1.5,
                spaceBetween: 5,
            },
        },

    });

    // faq-index
    $('.index-media__faq ul li').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });
    if ($('.index-media__faq ul li')) {
        $('.index-media__faq ul li').first().addClass('active').slideDown();
        $('.index-media__faq ul li').first().find('.index-media__faq-slide-items').slideDown();
    }
    // podcasts-index

    $('.index-media__episods ul li').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });
    if ($('.index-media__episods ul li')) {
        $('.index-media__episods ul li').first().addClass('active').slideDown('slow', function () {
            $(this).find('.index-media__episod-items').css('display', 'flex');
        });
        $('.index-media__episods ul li').first().find('.index-media__episod-items').slideDown();
    }
    $('.index-media__episod-items').on('click', function (e) {
        e.stopPropagation();
    });

    // index-podcast
    // plyr

    $('.plyer_podcast').each((i, e) => {
        new Plyr($(e), {invertTime: false});
    });
    // plyr-single-product
    const player_pro = new Plyr('#player-pro', {
        invertTime: false,
        i18n: {
            rewind: 'Rewind 5s',
            fastForward: 'Forward 5s',
        },
    });

    $('#next-prev .icon-prev').on('click', function () {
        player_pro.forward(5);
    });
    $('#next-prev .icon-next').on('click', function () {
        player_pro.rewind(5);
    });

    $('.single-product__video-offer [data-url]').on('click', function () {
        const url = $(this).attr('data-url');
        player_pro.source = {
            type: 'video',
            title: 'Example title',
            sources: [
                {
                    src: url,
                    type: 'video/mp4',
                },
            ],
        };
        player_pro.play();
    });

    player_pro.on('ended', function () {
        $(this).parents('.index-media__video--single-product').addClass('active');
        setTimeout(function () {
            const url = $('.single-product__video-first-offer').attr('data-url');
            player_pro.source = {
                type: 'video',
                title: 'Example title',
                sources: [
                    {
                        src: url,
                        type: 'video/mp4',
                    },
                ],
            };
            player_pro.play();
            setTimeout(function () {
                const time_d = $('.plyr--full-ui input[type="range"]').attr('aria-valuetext');
                const index = time_d.indexOf('of');
                const dd_time = time_d.substring(index + 3, time_d.length);
                $('.index-media__remind-au').text(dd_time);
            }, 500);
        }, 3000);
    });
    player_pro.on('playing', function () {
        $(this).parents('.index-media__video--single-product').removeClass('active');
    });
    // $('.single-product__video-offer').on('click',function () {
    //     var url_item = $('.single-product__video-offer').attr('data-url');
    //     player_pro.source = {
    //         type: 'video',
    //         title: 'Example title',
    //         sources: [
    //             {
    //                 src: url_item,
    //                 type: 'video/mp4'
    //             }
    //         ]
    //     };
    //     player_pro.play();
    //     $(this).parents('.single-product__video-offers').remove();
    // });
    // end-ply-single-product

    // video-index

    const player = new Plyr('#player', {
        invertTime: false,
        urls: {
            download: 'http://mirrors.standaloneinstaller.com/video-sample/jellyfish-25-mbps-hd-hevc.mp4',
        },
        i18n: {
            rewind: 'Rewind 5s',
            fastForward: 'Forward 5s',
        },
    });

    const player7 = new Plyr('#player7', {
        invertTime: false,
        controls: false,

    });
    player7.on('play', function () {
        $(this).parents('.index-about-us__video').addClass('playing');
    });
    player7.on('pause', function () {
        $(this).parents('.index-about-us__video').removeClass('playing');
    });
    // player8-video-single
    const player8 = new Plyr('#player8', {
        invertTime: false,
        controls: false,

    });
    player8.on('play', function () {
        $(this).parents('.video-single__video').addClass('playing');
    });
    player8.on('pause', function () {
        $(this).parents('.video-single__video').removeClass('playing');
    });

    player.download;
    //single product video
    player.on('play', function () {
        $(this).parents('.index-media__video').addClass('playing');
    });
    player.on('pause', function () {
        $(this).parents('.index-media__video').removeClass('playing');
    });

    $('.index-media__prev-next .icon-prev').on('click', function () {
        player.forward(5);
    });
    $('.index-media__prev-next .icon-next').on('click', function () {
        player.rewind(5);
    });
    // end-video-index
    if ($('.plyr--full-ui input[type="range"]').length) {
        setTimeout(function () {
            const time_d = $('.plyr--full-ui input[type="range"]').attr('aria-valuetext');
            const index = time_d.indexOf('of');
            const dd_time = time_d.substring(index + 3, time_d.length);
            $('.index-media__remind-au').text(dd_time);
        }, 500);
    }
    if ($('.plyr--full-ui input[type="range"]').length) {
        setTimeout(function () {
            const time_d = $('.plyr--full-ui input[type="range"]').attr('aria-valuetext');
            const index = time_d.indexOf('of');
            const dd_time = time_d.substring(index + 3, time_d.length);
            $('.index-media__remind-au').text(dd_time);
        }, 500);
    }

    // time_duration_audio
    function getDuration(src, cb) {
        const audio = new Audio();
        $(audio).on('loadedmetadata', function () {
            cb(audio.duration);
        });
        audio.src = src;
    }

    // share-head-page
    $('.breadcrumb-part__share').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active', 3000);
    });

    $('.single-blog__article-review ul li').on('click', function (e) {
        $(this).siblings().removeClass('active');
        $(this).toggleClass('active');
    });
    $('.single-blog__article-review ul li a').on('click', function (e) {
        e.preventDefault();
    });
    $('.site-footer__socials--single-product .close').on('click', function () {
        $(this).parents('li').siblings('.breadcrumb-part__share').removeClass('active');
    });
    $(document).on('click', '.single-blog__article-review ul li', function (e) {
        $(this).toggleClass('active');
    });
    // single-blog__service-side-slide
    new Swiper('.single-blog__service-side-slide .swiper-container', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
    });
    // side-bar-sticky
    if ($('.single-blog').length) {
        var sidebar = new StickySidebar('#sidebar', {
            containerSelector: '.stick_lk',
        });
    }
    if (window.innerWidth < 992) {
        if ($('.single-blog').length) {
            sidebar.destroy();
        }
    }
    // faq-single-blog
    // faq-index
    $('.single-blog__faq ul li').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });
    if ($('.single-blog__faq ul li')) {
        $('.single-blog__faq ul li').first().addClass('active').slideDown();
        $('.single-blog__faq ul li').first().find('.single-blog__faq-slide-items').slideDown();
    }
    // copy-to-clipboard

    $('.single-blog__copy i').on('click', function () {
        copyToClipboard('.single-blog__copy-texts em');
    });

    function copyToClipboard(element) {
        const $temp = $('<input>');
        $('body').append($temp);
        $temp.val($(element).text()).select();
        document.execCommand('copy');
        $temp.remove();
    }

    // second-index-slider
    new Swiper('.second-index-slider__offer-out-first-out .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 50,

        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

    });
    new Swiper('.second-index-slider__slide-baner-out .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.second-index-slider__navigation.swiper-next',
            prevEl: '.second-index-slider__navigation.swiper-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

    });
    // lightbox
    const lightbox = GLightbox();
    // offerslider-single-product
    new Swiper('.single-product__offer-slider .swiper-container', {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: 1,
        breakpoints: {
            1500: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
            1400: {
                slidesPerView: 3.7,
                spaceBetween: 20,
            },
            1300: {
                slidesPerView: 3.5,
                spaceBetween: 15,
            },
            1200: {
                slidesPerView: 3.2,
                spaceBetween: 15,
            },

            768: {
                slidesPerView: 3.2,
                spaceBetween: 5,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 5,
            },
            500: {
                slidesPerView: 1.5,
                spaceBetween: 5,

            },
            300: {
                slidesPerView: 1.1,
            },
        },

    });
    // single-product-faq
    // faq-index
    $('.single-product__faq ul li').on('click', function () {
        $(this).addClass('active').siblings().removeClass('active');
    });
    if ($('.single-product__faq ul li')) {
        $('.single-product__faq ul li').first().addClass('active').slideDown();
        $('.single-product__faq ul li').first().find('.single-product__faq-slide-items').slideDown();
    }
    // video-in video-single

    if ($('.video-single__video').length) {
        $('.video-single__over').on('click', function () {
            $('.video-single__video-inset video').get(0).play();
            $(this).removeClass('playing');
            $(this).addClass('playing');
        });
    }
    // search-header=panel
    $('.search-header i').on('click', function (e) {
        event.preventDefault();
        $(this).parent().find('input').toggleClass('show-input').focusin();
    });
    // search-archive
    $('.search-archive i').on('click', function (e) {
        event.preventDefault();
        $(this).toggleClass('icon-search');
        $(this).toggleClass('icon-close');
        $(this).parents('.products-list__head').toggleClass('show-search');
        $(this).parent().find('input').toggleClass('show-input').focusin();
    });

    // map in panel
    if ($('.panel').length) {
        $('.panel__select-locate').on('click', function show_map() {
            $(this).toggleClass('active');
            $(this).parents('.panel__add-new-addrs-part').siblings('#submit_ads_mapp').slideToggle();
            let swiperHeght = $('.mySwiper2').height();
            if ($(this).hasClass('active')) {
                $('.mySwiper2').css({'height': swiperHeght + 390});
            } else {
                $('.mySwiper2').css({'height': swiperHeght - 390});
            }
        });
    }
    // jalali-date-picker
    kamaDatepicker('date-picker', {buttonsColor: '#00a651', forceFarsiDigits: true});
    $('.pass-input input').on('keyup', function () {
        $(this).parent('.pass-input').addClass('eye');
    });
    if ($('.pass-input').length) {
        $('.pass-input i').on('click', function () {
            $(this).parent('.pass-input').find('input').toggleClass('hide');
            $(this).toggleClass('line');
        });
    }
    // trash in favorite-panel
    $('.post-cart--panel .icon-trash').on('click', function () {
        $(this).parent('.post-cart--panel').remove();
    });

    // file-upload-single-blog
    $('.panel__file-left input').change(function () {
        const name = $(this)[0].files[0].name;
        $('.panel__title-select ').toggleClass('active');
        $('.panel__file-name em').remove();
        $(this).parent().parent().find('.panel__file-name span').text(name);
    });

    $('.panel__title-select').on('click', function () {
        $(this).parents('.panel__file-name').remove();
    });

    $(document).on('change', '.panel__file-left input', (e) => {
        const name = e.target.files[0].name;
        $(e.target).parent().parent().find('.panel__file-name i').toggleClass('active');
        $(e.target).parent().parent().find('.panel__file-name em').remove();
        console.log($(e.target).parent().parent().find('.panel__file-name span'));
        $(e.target).parent().parent().find('.panel__file-name span').text(name);
    });
    $('.panel__file-name i').on('click', function () {
        $(this).parents('.panel__file-name').remove();
    });

    // blog__slider
    new Swiper('.blog__slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 40,
        centeredSlides: true,

    });
    let repeaterFiles = 1;
    $('.panel__file-add').on('click', function () {
        repeaterFiles++;
        let add_file = $(this).parents('.panel__select-file').find('.panel__file-right').html();
        add_file = $('<div class="panel__file-right">' + add_file + '</div>');
        $(add_file).addClass('sceonds');
        $(add_file).find('.panel__file-name > span').html('');
        $(add_file).find('input').attr('id', 'file-' + repeaterFiles);
        $(add_file).find('label').attr('for', 'file-' + repeaterFiles);
        $(this).parents('.panel__select-file-out ').append(add_file);
    });
    // index-media__selected-editor

    new Swiper('.index-media__selected-editor .swiper-container', {
        slidesPerView: 3.5,
        spaceBetween: 41,
        direction: 'vertical',
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },

    });

    // video-about
    $('.about-us__play i').on('click', function () {
        $('.about-us__video-single video').get(0).play();
        $('.about-us__video-single video').attr('controls', 'controls');
        $('.about-us__video-single ').addClass('playing');
    });
    $('.about-us__video-single video').on('pause', function () {
        $('.about-us__video-single ').removeClass('playing');
        $('.about-us__video-single video').removeAttr('controls', 'controls');
    });
    // our-team-slider
    new Swiper('.about-us__our-team .swiper-container', {
        slidesPerView: 5,
        spaceBetween: 24,
        pagination: {
            el: '.about-us__our-team-pagin.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            1500: {
                slidesPerView: 5,
                spaceBetween: 24,
            },
            1400: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1300: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 24,
            },

            768: {
                slidesPerView: 2.2,
                spaceBetween: 5,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 5,
            },
            500: {
                slidesPerView: 1.5,
                spaceBetween: 5,

            },
            300: {
                slidesPerView: 1.1,
            },
        },
    });
    // about-us-awards
    new Swiper('.about-us__awards .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 25,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            1500: {
                slidesPerView: 1,
                spaceBetween: 25,
            },
            1400: {
                slidesPerView: 1,
                spaceBetween: 15,
            },
            1300: {
                slidesPerView: 1,
                spaceBetween: 15,
            },
            1200: {
                slidesPerView: 1,
                spaceBetween: 15,
            },

            768: {
                slidesPerView: 3.2,
                spaceBetween: 5,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 5,
            },
            500: {
                slidesPerView: 1.5,
                spaceBetween: 5,

            },
            300: {
                slidesPerView: 1.1,
            },
        },
    });
    // accordion-faq
    $('.faq-content__content-out-itm-title').on('click', function () {
        $(this).find('i').toggleClass('rotate');
        const $this = $(this),
            $parent = $this.closest('.faq-content__content-out-items'),
            $over = $parent.find('.faq-content__content-out-itm-over'),
            content_height = $parent.find('.faq-content__content-out-itm-content').outerHeight();

        if ($parent.hasClass('active')) {
            $parent.removeClass('active');
            $over.removeAttr('style');
        } else {
            $parent.addClass('active');
            $over.css('max-height', content_height);
        }
    });

    $(document).ready(function () {
        if ($('.faq-content-single').length) {
            const $this = $('.faq-content__content-out-itm-title');
            $parent = $this.closest('.faq-content__content-out-items');
            $over = $parent.find('.faq-content__content-out-itm-over');
            content_height = $parent.find('.faq-content__content-out-itm-content').outerHeight();
            $over.css('max-height', content_height);
            $parent.addClass('active');
        }
    });

    // cat-product-filter
    const urlSearchParamsDel = new URLSearchParams(window.location.search);
    const cat = urlSearchParamsDel.get('cat-product');
    $('body').on('click', '.archive-content__side-filter-itms .cat-pro-append i', function () {
        $(this).parents('.cat-pro-append ').remove();
        const txt = $(this).siblings('em').text();
        const filters = cat.split(',');
        let c_name = null;
        filters.forEach(function (item, index) {
            if (txt.toUpperCase().indexOf(item) >= 0) {
                c_name = index;
            }
        });
        filters.splice(c_name, 1);
        urlSearchParamsDel.set('cat-product', filters.join(','));
        const query_string = urlSearchParamsDel.toString();
        document.location.search = query_string;
    });
    // brand-filter
    const urlSearchParamsDel_brand = new URLSearchParams(window.location.search);
    const brand_it = urlSearchParamsDel_brand.get('brand');
    $('body').on('click', '.archive-content__side-filter-itms .brand_append i', function () {
        $(this).parents('.brand_append').remove();
        const txt_brand = $(this).siblings('em').text();
        const filters_brand = brand_it.split(',');
        let c_name_brand = null;
        filters_brand.forEach(function (item, index) {
            if (txt_brand.toUpperCase().indexOf(item) >= 0) {
                c_name_brand = index;
            }
        });
        filters_brand.splice(c_name_brand, 1);
        urlSearchParamsDel_brand.set('brand', filters_brand.join(','));
        const query_string_brand = urlSearchParamsDel_brand.toString();
        document.location.search = query_string_brand;
    });
    $('.archive-content__side-filters-ttl i').on('click', function () {
        $(this).parents('.archive-content__side-filters').remove();
    });
    // in_stock_filter

    const urlSearchParamsDel_stock = new URLSearchParams(window.location.search);
    const stock_it = urlSearchParamsDel_stock.get('stock');
    $('body').on('click', '.archive-content__side-filter-itms .stock_append i', function () {
        $(this).parents('.stock_append').remove();
        const txt_stock = $(this).siblings('em').text();
        const filters_stock = stock_it.split(',');
        let c_name_stock = null;
        filters_stock.forEach(function (item, index) {
            if (txt_stock.toUpperCase().indexOf(item) >= 0) {
                c_name_stock = index;
            }
        });
        filters_stock.splice(c_name_stock, 1);
        urlSearchParamsDel_stock.set('stock', filters_stock.join(','));
        const query_string_stock = urlSearchParamsDel_stock.toString();
        document.location.search = query_string_stock;
    });
    $('.archive-content__side-filters-ttl i').on('click', function () {
        $(this).parents('.archive-content__side-filters-ttl').siblings('.stock_append').remove();
    });

    // discount-filetr
    const urlSearchParamsDel_discount = new URLSearchParams(window.location.search);
    const discount_it = urlSearchParamsDel_discount.get('discount');
    $('body').on('click', '.archive-content__side-filter-itms .discount_append i', function () {
        $(this).parents('.discount_append').remove();
        const txt_discount = $(this).siblings('em').text();
        const filters_discount = discount_it.split(',');
        let c_name_discount = null;
        filters_discount.forEach(function (item, index) {
            if (txt_discount.toUpperCase().indexOf(item) >= 0) {
                c_name_discount = index;
            }
        });
        filters_discount.splice(c_name_discount, 1);
        urlSearchParamsDel_discount.set('discount', filters_discount.join(','));
        const query_string_discount = urlSearchParamsDel_discount.toString();
        document.location.search = query_string_discount;
    });
    // jscrollpane
    $('.scroll-pane').jScrollPane();
    $(function () {
        $('.scroll-pane').jScrollPane({showArrows: true});
    });

    if ($('#range_slider_price').length) {
        const dragTapSlider = document.getElementById('range_slider_price');
        const min = $('.range-base-price').attr('data-min-price');
        const max = $('.range-base-price').attr('data-max-price');
        const current_min = $('.range-base-price').attr('data-current-min-price');
        const current_max = $('.range-base-price').attr('data-current-max-price');
        noUiSlider.create(dragTapSlider, {
            behaviour: 'drag-tap',
            start: [current_min, current_max],
            connect: true,
            range: {
                min: parseInt(min),
                max: parseInt(max),
            },
        });
        dragTapSlider.noUiSlider.on('update', function (values) {
            $('.max_range-price span').html(numberWithCommas(parseInt(values[1]).toFixed(0)));
            $('.min_range-price span').html(numberWithCommas(parseInt(values[0]).toFixed(0)));
            $('#min-price').val(parseInt(numberWithCommas(parseInt(values[0]).toFixed(0)).replaceAll(',', '')));
            $('#max-price').val(parseInt(numberWithCommas(parseInt(values[1]).toFixed(0)).replaceAll(',', '')));
        });
    }

    // notfound-404
    // var i = 8;
    // if ($('.notfound').length && i != 0) {
    //     setInterval(gohome, 1000);
    // }
    //
    // function gohome() {
    //     i--;
    //     if (i < 0) {
    //         window.location.href = '/';
    //         i = 0;
    //     }
    //
    //     $('.notfound__time span').text(i);
    //
    // }

    // back black body in mega-menu
    $(document).on('mouseover', '.site-header__bottom-r nav ul li.all', (e) => {
        $('body').addClass('hover');
    });

    $(document).on('mouseleave', '.site-header__bottom-r nav ul li.all', (e) => {
        $('body').removeClass('hover');
    });
    // end-back black body in mega-menu
    // modal-close

    // end-modal-close
    // offer-videos-time in single-product
    const vid = document.createElement('video');

    // create url to use as the src of the video
    const fileURL = $('.single-product__video-pic-offer').data('url');
    vid.src = fileURL;
    vid.ondurationchange = function () {
        $('.video_time').text((convertHMS(this.duration)));
    };

    function convertHMS(value) {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }

    // modal-show

    // $(document).on('click', '.single-product__pro-small-images a', e => {
    // 	e.preventDefault();
    // 	let $this = $(e.currentTarget);
    // 	$('.single-product__modal-out').addClass('shown').addClass('body');
    //
    // 	let target = $this.data('target');
    //
    // 	// if (target != undefined) {
    // 	//     let source = $(`.single-product__modal-tab-content a[data-target=${target}] img`).attr('data-img');
    // 	//     $('.single-product__modal-base-img img').attr('src', source);
    // 	//     $(`.single-product__modal-tab-content a[data-target=${target}]`).addClass('active').siblings().removeClass('active');
    // 	// }
    //
    // });

    $('.single-product__pro-small-images span').on('click', function (e) {
        e.preventDefault();
        const $this = $(e.currentTarget);
        $('.single-product__modal-out').addClass('shown').addClass('body');
        const target = $this.data('target');
        console.log(target);
        $(`.single-product__modal-tab-content span[data-target=${target}]`).addClass('active').siblings().removeClass('active');
        $(`.single-product__modal-tab-content span[data-target=${target}]`).trigger('click');
    });

    $(document).on('click', '.single-product__modal-tab-content a', (e) => {
        e.preventDefault();
        const $this = $(e.currentTarget),
            source = $this.find('img').attr('data-img');

        $('.single-product__modal-base-img img').attr('src', source);
        $this.addClass('active')
            .siblings()
            .removeClass('active');
    });

    $('.single-product__modal-video').on('click', function (e) {
        e.preventDefault();
        $(this).addClass('active').siblings().removeClass('active');
        const src = $(this).find('source').attr('src');
        $('.single-product__modal-base-img').html('<video width="320" height="240" controls>' +
            '  <source src="' + src + '" type="video/mp4"> </video>');
    });

    $('.single-product__modal-img').on('click', function (e) {
        e.preventDefault();
        $(this).addClass('active').siblings().removeClass('active');
        const src = $(this).children('img').attr('data-img');
        $('.single-product__modal-base-img').html('<img src="' + src + '">');
    });

    $('.next-img').on('click', function () {
        const active_ele = $('.single-product__modal-items .tab-content .single-product__modal-tab-content').find('.active').prev();
        active_ele.trigger('click');
    });
    $('.prev-img').on('click', function () {
        const active_ele = $('.single-product__modal-items .tab-content .single-product__modal-tab-content').find('.active').next();
        active_ele.trigger('click');
    });

    // end-modal-show
    $('.single-product__pro-base-img').elevateZoom({
        zoomType: "inner",
        cursor: "crosshair"
    });


    $('.single-product__pro-small-images span').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('hover');
        $('body').css('overflow', 'hidden');
    });

    $('.single-product__modal-title i').on('click', function (e) {
        $('body').removeClass('hover');
        $(e.currentTarget).parents('.single-product__modal-out').removeClass('shown');
        $('body').css('overflow', 'visible');
    });

    // $( 'body' ).on( 'click', function( e ) {
    // 	$( 'body' ).removeClass( 'hover' );
    // 	$( '.single-product__modal-out' ).removeClass( 'shown' );
    // 	$( 'body' ).css( 'overflow', 'visible' );
    // } );

    // login-header
    $('.site-header__after-login-user').on('click', function (e) {
        e.preventDefault();
        $('.site-header__product-list').removeClass('active');
        $(this).siblings('.site-header__login-list').slideToggle();
    });
    $(document).click(function (event) {
        if (!$(event.target).closest('.site-header__after-login-user,.site-header__login-list').length) {
            $('.site-header__login-list').slideUp();
        }
    });

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete(name);
    const params = Object.fromEntries(urlSearchParams.entries());
    const params_items = params;
    if (params_items['cat-product'] != undefined) {
        const cat_product = params_items['cat-product'].split(',');
        if (cat_product) {
            cat_product.forEach(function (item, index) {
                if (cat_product) {
                    const el = $(`<li class="cat-pro-append">
							<em>${item}</em>
							<i class="icon-close"></i>
						</li>
			`);
                    $('.archive-content__side-filter-itms').append(el);
                }
            });
        }
    }

    $('.variation select').on('change', function () {
        let val = $(this).children('option').val();
        val = val.split('/');
        $('.single-blog__offer-price-ns ins').text(val[1]);
        let href = $('.single-blog__offer-buy.single-blog__offer-buy--single-product a').attr('href');
        href = href.split('&');
        const add_to_cart = href[0].split('=');
        $('.single-blog__offer-buy.single-blog__offer-buy--single-product a').attr('href', add_to_cart[0] + '=' + val[0] + '&' + href[1]);
    });

    $('.select_quantity select').on('change', function () {
        const val = $(this).children('option').val();
        let href = $('.single-blog__offer-buy.single-blog__offer-buy--single-product a').attr('href');
        href = href.split('&');
        const quality = href[1].split('=');
        $('.single-blog__offer-buy.single-blog__offer-buy--single-product a').attr('href', href[0] + '&' + quality[0] + '=' + val);
    });

    // brand_filter
    const urlSearchParams_brand = new URLSearchParams(window.location.search);
    urlSearchParams_brand.delete(name);
    const params_brand = Object.fromEntries(urlSearchParams.entries());
    const params_items_brand = params_brand;
    if (params_items_brand.brand != undefined) {
        const brand = params_items_brand.brand.split(',');
        if (brand) {
            brand.forEach(function (item, index) {
                if (brand != '') {
                    const el = $(`<li class="brand_append">
							<em>${item}</em>
							<i class="icon-close"></i>
						</li>
			`);
                    $('.archive-content__side-filter-itms').append(el);
                }
            });
        }
    }
    // in_stock_filter
    const urlSearchParams_stock = new URLSearchParams(window.location.search);
    urlSearchParams_stock.delete(name);
    const params_stock = Object.fromEntries(urlSearchParams_stock.entries());
    const params_items_stock = params_stock;
    if (params_items_stock.stock != undefined) {
        const stock = params_items_stock.stock;
        if (stock) {
            const el = $(`<li class="stock_append">
					<em>موجود</em>
					<i class="icon-close"></i>
				</li>
	`);
            $('.archive-content__side-filter-itms').append(el);
        }
    }
    // mojod
    const urlSearchParams_discount = new URLSearchParams(window.location.search);
    urlSearchParams_discount.delete(name);
    const params_discount = Object.fromEntries(urlSearchParams_discount.entries());
    const params_items_discount = params_discount;
    if (params_items_discount.discount != undefined) {
        const discount = params_items_discount.discount;
        if (discount) {
            const el = $(`<li class="discount_append">
					<em>شامل تخفیف</em>
					<i class="icon-close"></i>
				</li>
	`);
            $('.archive-content__side-filter-itms').append(el);
        }
    }

    // cart-list
    $('.site-header__buy-cart').on('click', function (e) {
        e.preventDefault();
        $('.site-header__login-list').slideUp();

        $('.site-header__login-list').slideUp();
        $(this).siblings('.site-header__product-list').toggleClass('active');
    });
    $(document).click(function (event) {
        if (!$(event.target).closest('.site-header__buy-cart').length) {
            $('.site-header__product-list').removeClass('active');
        }
    });
    $('.site-header__product-list-item i.icon-close').on('click', function () {
        $(this).parents('.site-header__product-list-item').remove();
        $(this).parents('.scroll-pane').addClass('height-scroll');
    });
    // single-content__side-single-res
    $('.single-blog__side-res').on('click', function () {
        $(this).siblings('.single-blog__side-out').slideDown(300);
        $(this).parents('.single-blog__side').addClass('active');
    });
    $('.single-blog__side-res .icon-close').on('click', function (e) {
        e.stopPropagation();
        $('.side-up .single-blog__side-out').slideUp(300);
        $('.single-blog__side').removeClass('active');
    });

    // delete-cart
    $('.cart__content-left').on('click', function () {
        $(this).parent('.cart__content-title').siblings('.cart__content-products').remove();
    });
    // tab-panel-responsive
    $('.panel__tab-ttl').on('click', function () {
        $('.panel__tab-title-responsive').slideToggle();
    });

    $('.panel__tab-title-responsive [data-tab]').on('click', function () {
        $('.panel__tab-ttl').toggleClass('active');
        const title = $(this).html();
        $('.panel__tab-ttl').html(title);
        $('.panel__tab-title-responsive').slideUp();
    });
    // site-footer__newsletter
    $('.site-footer__newsletter form').keyup(function () {
        $(this).find('button').addClass('active');
    });
    // progres-product-Function
    $(document).ready(function () {
        $('.index-about-us__slide-rate-inset').each(function () {
            data_width = $(this).attr('data-width');
            $(this).css('width', data_width);
        });
    });

    // form-login
    $('.login__input input').on('input', function () {
        if ($(this).length > 0) {
            $(this).siblings('label').addClass('active');
        } else {
            $(this).siblings('label').removeClass('active');
        }
    });
    // post-like-ajax
    $('.like-ajax').on('click', function (e) {
        e.preventDefault();
        const ele = $(this);
        const data = {};
        data.postid = ele.data('postid');
        data.nonce = ele.data('nonce');
        data.type = ele.data('type');
        data.action = 'ivahid_post_like';
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: global_object.ajax_url,
            data,
            success(data) {
                if (data.status == 1) {
                    Toast.fire({
                        icon: 'success',
                        title: 'با موفقیت انجام شد',
                        footer: data.message,
                    });
                    ele.toggleClass('active');

                    if (data.type == 'remove') {
                        ele.find('i').removeClass('icon-heart');
                        ele.find('i').addClass('icon-heart-fill');
                    } else {
                        ele.find('i').removeClass('icon-heart-fill');
                        ele.find('i').addClass('icon-heart');
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'متاسفانه انجام نشد',
                        footer: data.message,
                    });
                }
            },
            error() {
                Toast.fire({
                    icon: 'error',
                    title: 'متاسفانه انجام نشد',
                    footer: 'در ارسال درخواست مشکلی وجود دارد.',
                });
            },
        });
    });
    // comment-like
    $('.cm-like-ajax').on('click', function (e) {
        e.preventDefault();
        const ele = $(this);
        const parent = $(this).parents('.comment-item');
        const data = {};
        data.postid = parent.data('postid');
        data.commentid = parent.data('commentid');
        data.nonce = parent.data('nonce');
        data.type = ele.data('type');
        data.action = 'ivahid_comment_like';
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: global_object.ajax_url,
            data,
            success(data) {
                if (data.status == 1) {
                    ele.toggleClass('active');
                    if (ele.data('type') == 'like' && parent.find('.like-count')) {
                        parent.find('.like-count').html(data.info.count);
                    }
                    if (ele.data('type') == 'dislike' && parent.find('.dislike-count')) {
                        parent.find('.dislike-count').html(data.info.count);
                    }
                    Toast.fire({
                        icon: 'success',
                        title: 'با موفقیت انجام شد',
                        footer: data.message,
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'متاسفانه انجام نشد',
                        footer: data.message,
                    });
                }
            },
            error() {
                Toast.fire({
                    icon: 'error',
                    title: 'متاسفانه انجام نشد',
                    footer: 'در ارسال درخواست مشکلی وجود دارد.',
                });
            },
        });
    });
    // print-page
    $('.print-page a').on('click', function (e) {
        e.preventDefault();
        window.print();
    });
    // side-title-single-blog
    if ($('.single-blog').length) {
        const titles = [];
        $('.content h2').each(function (i) {
            titles.push($(this).text());
            $(this).attr('id', 'title_' + i);
        });
        titles.forEach(function (e, i) {
            const li = '<li class=""><a href="#title_' + i + '">' + e + '</a></li>';
            $('.single-blog__offer-posts ul').append(li);
        });
        $('.single-blog__offer-posts li a').mousedown(function () {
            $('.single-blog__offer-posts li a').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        });
    }
    // end-side-title-single-blog
    // expand-archive

    // show_more-archive

    $('.archive-content__description').ready(function (e) {
        const pTag = $('.archive-content__description').find('p');
        const btnMore = $('.archive-content__description').find('button');
        if (pTag.height() < 95) {
            btnMore.hide();
        }
    });
    $('.archive-content__description-more').on('click', function () {
        $('.archive-content__descriptionContent').toggleClass('active');
        if ($('.archive-content__descriptionContent').hasClass('active')) {
            (this).find('span').text('مشاهده کمتر');
        } else {
            $(this).find('span').text('مشاهده بیشتر');
        }
    });

    /* filter shop category */
    $('[data-filter]').on('change', function () {
        const filters = [];
        const filter = $(this).attr('data-filter');
        $('[data-filter=' + filter + ']:checked').each(function () {
            filters.push($(this).val());
        });
        insertParam(filter, filters.toString());
    });
    // search-cat-archive
    if ($('.search-cat').length) {
        const input = document.getElementById('search-cat');
        input.onkeyup = function () {
            const filter = input.value.toUpperCase();
            const lis = $('.list-category-pro');
            for (let i = 0; i < lis.length; i++) {
                const name = $(lis[i]).find('label').html();

                if (name.toUpperCase().indexOf(filter) >= 0) {
                    lis[i].style.display = 'block';
                } else {
                    lis[i].style.display = 'none';
                }
            }
        };
    }
    // search-brands
    if ($('.search-brands').length) {
        const input_brnd = document.getElementById('search-brands');

        input_brnd.onkeyup = function () {
            const filter_brnds = input_brnd.value.toUpperCase();
            const lis_brnd = $('.list-category-brands');

            for (let i = 0; i < lis_brnd.length; i++) {
                const name_brnd = $(lis_brnd[i]).find('label').html();

                if (name_brnd.toUpperCase().indexOf(filter_brnds) >= 0) {
                    lis_brnd[i].style.display = 'block';
                } else {
                    lis_brnd[i].style.display = 'none';
                }
            }
        };
    }
});

function quality_ajax(e, code, value, pid) {
    e.preventDefault();
    $.ajax({
        url: global_object.ajax_url,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'wp_update_quality',
            code,
            value,
            pid,
        },
        done(r) {
            console.log(1115515);
        },
        success(response) {
            Toast.fire({
                icon: 'success',
                title: 'تعداد با موفقیت بروز شد',
            });
            location.reload();
        },
        error(error) {
            let status = error.responseText;
            status = status.split(')');
            status = JSON.parse(status[1]);
            if (status.success = 'success') {
                Toast.fire({
                    icon: 'success',
                    title: 'تعداد با موفقیت بروز شد',
                });
                location.reload();
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'مشکلی در ارسال درخواست یافت شد',
                });
            }
        },

    });
}

/*-------------------------------------
</> @author  Alireza Bagheri - agriraz
</> cart update quality
/*------------------------------------*/
$('.count-item-cart__plus').on('click', function (e) {
    const inp_value = $(this).siblings('.qly').val();
    $(this).siblings('.qly').val(parseInt(inp_value) + 1);
    if ($(this).siblings('.count-item-cart__minus').children().hasClass('cart__remove-prod')) {
        $(this).siblings('.count-item-cart__minus').html('<i class="icon-minus"></i>');
    }
    //get value input
    const inp_name = $(this).siblings('.qly').attr('name'),
        inp_code = inp_name.split('/'),
        inp_val = $(this).siblings('.qly').val();
    // ajax update quality
    quality_ajax(e, inp_code[0], inp_val, inp_code[1]);
});
$('.count-item-cart__minus').on('click', function (e) {
    const inp_value = $(this).siblings('.qly').val();
    if (inp_value > 1) {
        $(this).siblings('.qly').val(parseInt(inp_value) - 1);
        if ($(this).siblings('.qly').val() == 1) {
            const remov_ele = $(this).parent('.count-item-cart').siblings('.cart__remove-prod').clone();
            $(this).html(remov_ele);
        }
    } else {
        $(this).children('i').removeClass('icon-minus').addClass('icon-trash');
    }
    //get value input
    const inp_name = $(this).siblings('.qly').attr('name'),
        inp_code = inp_name.split('/'),
        inp_val = $(this).siblings('.qly').val();
    // ajax update quality
    quality_ajax(e, inp_code[0], inp_val, inp_code[1]);
});
/*-------------------------------------
</> @author  Alireza Bagheri - agriraz
</> change shipping method
/*------------------------------------*/
$('.cart__item_shipping').on('click', function () {
    console.log();
    const shipping_price = $(this).find('.cart__item_shipping__price').children('ins').data('shipping');
    $('#shipping_cost').find('.shopping-cart-information__item-price').text(shipping_price);
    let total_sum = $('#total_sum').find('.shopping-cart-information__item-price').data('total');
    total_sum = parseInt(total_sum) + parseInt(shipping_price);
    $('#total_sum').find('.shopping-cart-information__item-price').text(total_sum);
});

/*-------------------------------------
</> @author  Alireza Bagheri - agriraz
</> set first item for checkout page
/*------------------------------------*/
if ($('.checkout_form_in_cart').length) {
    $('.cart_swiper-button-next').on('click', function () {
        $('.cart-address .cart-address__content').first().children('.cart-address__content-items').trigger("click");
        console.log($('.cart-address .cart-address__content').first().children('.cart-address__content-items'))
        $('.cart__content .cart__item_shipping_all').first().children('.cart__item_shipping').trigger("click");
        $('.cart__payment__all .cart__payment__item').first().children('label').trigger("click");
    });

    if ($('#cart-offer').length) {
        setTimeout(function () {
            $('.cart_swiper-button-next').trigger('click');
        }, 500);
    }
}

// function searchCategoryProduct(element){
// 	$.ajax({
// 		url: global_object.ajax_url,
// 		type: 'post',
// 		data: { action: 'data_fetch', keyword: $(element).val()},
// 		success: function(data) {
// 			$('.products-list__ul--product .jspPane').html( data );
// 		}
// 	});
// }

/**
 * ticker typing
 *
 * @param {int} [typeSpeed] - Speed of typing.
 * @param {int} [delay] - delay in typing.
 */
$.fn.tickerTyping = function () {
    const typeSpeed = 30,
        delay = 1000,
        $this = $(this),
        $children = $this.children(),
        texts = $children.map(function () {
            return $(this).text();
        }).get();
    let i = 0,
        s = 0;
    var step = function () {
        const $current = $children.eq(i),
            $a = $current.find('a:first');
        $children.hide();
        $current.show();
        $a.text(texts[i].substr(0, s));
        if (s == texts[i].length) {
            s = 0;
            if (i == $children.length - 1) {
                i = 0;
            } else {
                i++;
            }
            setTimeout(step, delay);
        } else {
            setTimeout(step, typeSpeed);
        }
        s++;
    };
    step();
};

/* insert parameter to url */
function insertParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    const kvp = document.location.search.substr(1).split('&');
    let i = 0;

    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            const pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=');
    }

    // can return this or...
    const params = kvp.join('&');

    // reload page with new params
    document.location.search = decodeURIComponent(params);
}

/**
 * generate tab
 *
 * @param {string} [tabIndex] - The tabIndex is value of data-tabindex in parent tab.
 */
function tabInit(tabIndex) {
    if (typeof tabIndex === 'undefined' || tabIndex === null) {
        $('[data-tabindex]').each(function () {
            let ele = $(this),
                dataTabindex = ele.attr('data-tabindex'),
                hasTrueTab = false;
            ele.find(' .tab-content [data-tabc][data-parent="' + dataTabindex + '"]').hide();
            ele.find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"]').each(function () {
                let i;
                if ($(this).hasClass('active')) {
                    i = $(this).attr('data-tab');
                    ele.find('.tab-content [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]').addClass('active').show();
                    hasTrueTab = true;
                }
                if (hasTrueTab !== true) {
                    ele.find('.tab-content [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]:first-of-type').show();
                    ele.find('.tab-title [data-tab="' + i + '"][data-parent="' + dataTabindex + '"]:first-of-type').addClass('active');
                }
            });
            ele.find(' .tab-title [data-tab][data-parent="' + dataTabindex + '"]').click(function () {
                if (!$(this).hasClass('active')) {
                    const t = $(this).attr('data-tab'),
                        oldActiveTab = ele.find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"].active').attr('data-tab');
                    ele.find(' .tab-title  [data-tab=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active');
                    ele.find(' .tab-content  [data-tabc=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active').hide();
                    $(this).addClass('active');
                    ele.find(' .tab-content  [data-tabc="' + t + '"][data-parent="' + dataTabindex + '"]').show();
                }
            });
        });
    } else {
        const dataTabindex = tabIndex;
        tabIndex = '[data-tabindex=' + tabIndex + ']';
        $(tabIndex).find(' .tab-content [data-tabc][data-parent="' + dataTabindex + '"]').hide();
        $(tabIndex).find(' .tab-title [data-tab][data-parent="' + dataTabindex + '"]').each(function () {
            let i;
            if ($(this).hasClass('active')) {
                i = $(this).attr('data-tab');
                $(tabIndex).find(' .tab-content  [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]').addClass('active').show();
            }
        });
        $(tabIndex).find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"]').click(function () {
            if (!$(this).hasClass('active')) {
                const t = $(this).attr('data-tab'),
                    oldActiveTab = $(tabIndex).find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"].active').attr('data-tab');
                $(tabIndex).find(' .tab-title  [data-tab=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active');
                $(tabIndex).find(' .tab-content  [data-tabc=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active').hide();
                $(this).addClass('active');
                $(tabIndex).find(' .tab-content  [data-tabc="' + t + '"][data-parent="' + dataTabindex + '"]').show();
            }
        });
    }
    $('body').on('click', '[data-tabindex-current]', function () {
        const dataTabindex = $(this).attr('data-tabindex-current'),
            ele = $('[data-tabindex="' + dataTabindex + '"]'),
            tabCurrent = $(this).attr('data-tab-current');
        ele.find('.tab-title  [data-tab][data-parent="' + dataTabindex + '"]').removeClass('active');
        ele.find('.tab-content  [data-tabc][data-parent="' + dataTabindex + '"]').hide();
        ele.find('.tab-title  [data-tab="' + tabCurrent + '"][data-parent="' + dataTabindex + '"]').addClass('active');
        ele.find('.tab-content  [data-tabc="' + tabCurrent + '"][data-parent="' + dataTabindex + '"]').fadeIn();
    });
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * block loader
 *
 * @param status
 * @param element
 * @param title
 */
function blockLoader(status, element, title) {
    let loaderDom;
    if (typeof status === 'undefined' || status === null) {
        status = 'show';
    }
    if (typeof element === 'undefined' || element === null) {
        element = 'body';
    }
    if (typeof title === 'undefined' || title === null) {
        title = 'در حال بارگذاری ...';
    }
    loaderDom = '<div class="loader-overly"><div class="loader"><div class="spinner"><div></div><div></div><div></div><div></div></div>' + title + '</div>';
    const elementName = element;
    if (typeof element === 'string') {
        element = $(element);
    }
    if (status == 'show') {
        element.append(loaderDom);
        element.find('.loader-overly').show();
        if (elementName == 'body') {
            element.addClass('loader-locked');
            element.find('.loader-overly').addClass('loader-overly_fixed');
        }
    } else {
        element.find('.loader-overly').hide();
        element.find('.loader-overly').remove();
        if (elementName == 'body') {
            element.removeClass('loader-locked');
        }
    }
}

$.fn.extend({
    donetyping(callback, timeout) {
        timeout = timeout || 1e3; // 1 second default timeout
        let timeoutReference,
            doneTyping = function (el) {
                if (!timeoutReference) {
                    return;
                }
                timeoutReference = null;
                callback.call(el);
            };
        return this.each(function (i, el) {
            const $el = $(el);
            // Chrome Fix (Use keyup over keypress to detect backspace)
            // thank you @palerdot
            $el.is(':input') && $el.on('keyup keypress paste', function (e) {
                // This catches the backspace button in chrome, but also prevents
                // the event from triggering too preemptively. Without this line,
                // using tab/shift+tab will make the focused element fire the callback.
                if (e.type == 'keyup' && e.keyCode != 8) {
                    return;
                }

                // Check if timeout has been set. If it has, "reset" the clock and
                // start over again.
                if (timeoutReference) {
                    clearTimeout(timeoutReference);
                }
                timeoutReference = setTimeout(function () {
                    // if we made it here, our timeout has elapsed. Fire the
                    // callback
                    doneTyping(el);
                }, timeout);
            }).on('blur', function () {
                // If we can, fire the event since we're leaving the field
                doneTyping(el);
            });
        });
    },
});

/* serialize object */
$.fn.serializeObject = function () {
    'use strict';
    const a = {},
        b = function (b, c) {
            const d = a[c.name];
            'undefined' !== typeof d && d !== null ? $.isArray(d) ? d.push(c.value) : a[c.name] = [d, c.value] : a[c.name] = c.value;
        };
    return $.each(this.serializeArray(), b), a;
};
/*
Replace all SVG images with inline SVG */
jQuery('img.svg').each(function () {
    const $img = jQuery(this);
    const imgID = $img.attr('id');
    const imgClass = $img.attr('class');
    const imgURL = $img.attr('src');

    jQuery.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        let $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Replace image with new SVG
        $img.replaceWith($svg);
    }, 'xml');
});

// map
function google_maps() {
    const divs = document.querySelectorAll('.google-map');
    let i = 0;
    if (divs.length) {
        Object.entries(divs).map((object) => {
            i++;
            const lat = parseFloat(object[1].getAttribute('data-lat'));
            const long = parseFloat(object[1].getAttribute('data-long'));
            const myLatLng = {lat, lng: long};
            const data_id = 'googlemap' + i;
            object[1].setAttribute('id', data_id);
            const mapProp = {
                center: new google.maps.LatLng(lat, long),
                zoom: 18,
            };

            const map = new google.maps.Map(document.getElementById(data_id), mapProp);
            const marker = new google.maps.Marker({
                position: myLatLng,
                map,
                draggable: true,
                animation: google.maps.Animation.DROP,
            });

            function toggleBounce() {
                if (marker.getAnimation() !== null) {
                    marker.setAnimation(null);
                } else {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                }
            }
        });
    }
}

// say-hello
if (window.console) {
    console.log('%c👋 ivahid Says Hello %civahid is waiting for programmer like you, send resume: %cinfo@ivahid.com', 'color: #ff4342; font-size: 2rem; font-family: Arial; font-weight: bolder', 'color: #454851; font-size: 1.1rem; font-family: Arial;', 'color: #1a9df4; font-size: 1.1rem; font-family: Arial;');
}
const ROOT_CLASS_NAME = 'digit-flipper';

class DigitFlipper {
    constructor(element, options = {
        number: 9,
        iterationCount: 9,
    }) {
        // First, some parameter sanitizing:
        if (options.number > 9 || options.number < 0) {
            return;
        }

        this.options = Object.assign({}, options);

        if (!this.options.number) {
            this.options.number = 9;
        }
        if (!this.options.iterationCount) {
            this.options.iterationCount = 9;
        }

        // Adjusting the number of iterations,
        // in case our numbers end up in the negatives:
        if (this.options.number - this.options.iterationCount < 0) {
            this.options.iterationCount = this.options.number;
        }

        this.element = element;
        this.digitClassName = `${ROOT_CLASS_NAME}__digit`;
        this.topClassName = `${this.digitClassName}-top`;
        this.bottomClassName = `${this.digitClassName}-bottom`;
        this.flipTopClass = `${this.digitClassName}--flip-top`;
        this.flipBottomClass = `${this.digitClassName}--flip-bottom`;
        this.flipDoneClass = `${this.digitClassName}--flip-done`;
        this.DOMNodes = [];
        this.flipDuration = parseFloat(
            (window.getComputedStyle(document.documentElement).getPropertyValue('--flip-duration') || '1s').replace('s', ''));

        this._init();

        return this;
    }

    _init() {
        this._populateDOM();
    }

    // creates DOM elements for each digit and all of its "iterations"
    _populateDOM() {
        let i = this.options.number - this.options.iterationCount;
        for (i; i <= this.options.number; i++) {
            const digit = document.createElement('span'),
                digitTop = document.createElement('span'),
                digitBottom = document.createElement('span'),
                digitText = document.createTextNode(i);

            digit.className = this.digitClassName;
            digitTop.className = this.topClassName;
            digitBottom.className = this.bottomClassName;

            digitTop.appendChild(digitText);
            digitBottom.appendChild(digitText.cloneNode());
            digit.appendChild(digitTop);
            digit.appendChild(digitBottom);

            this.DOMNodes.push(digit);
            this.element.insertAdjacentElement('afterbegin', digit);
        }
    }

    // runs the animtion sequence for the digit
    flip() {
        this.DOMNodes.forEach((node, index) => {
            const nextNode = this.DOMNodes[index + 1];
            const delay = this.flipDuration * index * 1000;

            // The flipBottomClass turns the bottom half
            // down from it's inital state of 90deg

            // The flipTopClass turns the top half
            // down from it's inital state of 0deg

            const t1 = setTimeout(() => {
                node.classList.add(this.flipBottomClass);
                clearTimeout(t1);
                const t2 = setTimeout(() => {
                    if (nextNode) {
                        node.classList.add(this.flipTopClass);
                    }
                    clearTimeout(t2);
                    const t3 = setTimeout(() => {
                        node.style.zIndex = index + 1;
                        clearTimeout(t3);
                    }, this.flipDuration);
                }, this.flipDuration);
            }, delay);
        });
    }
}

class FlipCounter {
    constructor(element, value) {
        if (typeof value !== 'number') {
            return;
        }

        this.element = element;
        this.targetNumber = value;
        this.targetDigits = [];
        this.numDigits = this.targetNumber.toString().length;
        this.DOMNodes = [];
        this.flipperInstances = [];

        // separate the digits of the value arg
        for (let i = 0; i < this.numDigits; i++) {
            this.targetDigits.push(this.targetNumber.toString()[i]);
        }

        this.populateDOM();
        this.populateInstanceArray();
    }

    // creates wrapper elements for each digit
    populateDOM() {
        this.element.innerHTML = '';

        let i = 0;
        for (i; i < this.numDigits; i++) {
            const container = document.createElement('span');
            container.className = ROOT_CLASS_NAME;

            this.element.appendChild(container);
            this.DOMNodes.push(container);
        }
    }

    // instantiate a DigitFlipper object for each digit
    populateInstanceArray() {
        this.DOMNodes.forEach((digit, index) => {
            this.flipperInstances.push(
                new DigitFlipper(digit, {
                    number: this.targetDigits[index],
                    iterationCount: 4,
                }));
        });
    }

    // runs the animation, with a 200ms stagger
    play() {
        this.flipperInstances.forEach((instance, index) => {
            const delay = index * 200;
            setTimeout(() => instance.flip(), delay);
        });
    }
}

// awards-swiper
const awards_swiper = new Swiper('.contact-us__awards .swiper-container', {
    slidesPerView: 5.2,
    spaceBetween: 30,
    breakpoints: {
        1500: {
            slidesPerView: 5.2,
            spaceBetween: 30,
        },
        1400: {
            slidesPerView: 3.7,
            spaceBetween: 15,
        },
        1300: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        1200: {
            slidesPerView: 3.2,
            spaceBetween: 15,
        },

        768: {
            slidesPerView: 3.2,
            spaceBetween: 5,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 5,
        },
        500: {
            slidesPerView: 1.5,
            spaceBetween: 5,

        },
        300: {
            slidesPerView: 1.1,
        },
    },
});

if ($('.notfound').length) {
    let counter = 8;
    const span = $('.notfound__time').children('span');
    const myTimer = setInterval(function () {
        if (counter >= 0) {
            span.text(counter);
        }
        counter--;
        if (counter <= 0) {
            clearInterval(myTimer);
            window.location.href = '/';
        }
    }, 1000);

    const scene = document.getElementById('parallax');
    const parallaxInstance = new Parallax(scene);
}

$(document).ready(function () {
    function optionLink(elementId) {
        const element = '#' + elementId;
        $(element).change(function () {
            optionType = $(element + ' option').val();
            window.location.href = optionType;
            optionType.attr('selected', 'selected');
        });
    }

    optionLink('header-cat');

    $('.sweet-share').on('click', function (e) {
        url = $(this).attr('post-url');

        const html = '<ul class="social-media-style"><li><a href="http://telegram.me/' + url + '" title="تلگرام" target="_blank" rel="nofollow" class=""><i class="icon-telegram"></i></a></li><li><a href="https://api.whatsapp.com/send?text=' + url + '" title="واتس اپ" target="_blank" rel="nofollow" class=""><i class="icon-whatsapp"></i></a></li><li><a href="https://twitter.com/share?text=&url=' + url + '" title="توییتر" target="_blank" rel="nofollow" class=""><i class="icon-twitter"></i></a></li><li><a href="http://instagram.com/' + url + '" title="اینستاگرام" target="_blank" rel="nofollow" class=""><i class="icon-instagram"></i></a></li><li><a href="https://www.linkedin.com/shareArticle?mini=true&url=' + url + '" title="لینکدین" target="_blank" rel="nofollow" class=""><i class="icon-linkedin"></i></a></li><li><a href="https://www.facebook.com/sharer/sharer.php?u=' + url + '" title="فیسبوک" class="active" target="_blank" rel="nofollow"><i class="icon-facebook"></i></a></li></ul>';

        Swal.fire({
            title: '<strong>با دوستات به اشتراک بذار</strong>',
            html,
            showCloseButton: false,
            showCancelButton: true,
            showConfirmButton: false,
            customClass: 'swal-wide',
            cancelButtonText:
                'انصراف',
            cancelButtonAriaLabel: 'انصراف',
        });
        Swal.fire({
            title: '<strong>با دوستات به اشتراک بذار</strong>',
            html,
            showCloseButton: false,
            showCancelButton: true,
            showConfirmButton: false,
            customClass: 'swal-wide',
            cancelButtonText:
                'انصراف',
            cancelButtonAriaLabel: 'انصراف',
        });
    });
    cancelButtonAriaLabel: 'انصراف';
});

// add favorite
$('.update-list').on('click', function (e) {
    e.preventDefault();
    let ele = $(this),
        listName = ele.attr('data-list'),
        postId = ele.attr('data-postid'),
        exist = ele.attr('data-exist'),
        action = 'update_user_list',
        updateType = '';

    if (exist == 'true') {
        updateType = 'remove';
    } else {
        updateType = 'add';
    }

    const data = {
        list_name: listName,
        post_id: postId,
        action,
        update_type: updateType,
    };
    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: global_object.ajax_url,
        data,
        success: function success(data) {
            if (data.status == 1) {
                if (data.info.type == 'add') {
                    ele.attr('data-exist', true);
                    ele.addClass('active');
                    /* .user list update */
                    if ($('.education__favorites .update-list').length) {
                        $('.education__favorites span').html('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 477.534 477.534" style="enable-background:new 0 0 477.534 477.534;" xml:space="preserve"><g><g><path d="M438.482,58.61c-24.7-26.549-59.311-41.655-95.573-41.711c-36.291,0.042-70.938,15.14-95.676,41.694l-8.431,8.909l-8.431-8.909C181.284,5.762,98.663,2.728,45.832,51.815c-2.341,2.176-4.602,4.436-6.778,6.778c-52.072,56.166-52.072,142.968,0,199.134l187.358,197.581c6.482,6.843,17.284,7.136,24.127,0.654c0.224-0.212,0.442-0.43,0.654-0.654l187.29-197.581C490.551,201.567,490.551,114.77,438.482,58.61z"/></g></g></svg>' + 'اضافه شده');
                    }
                    if ($('.sidebar-top__study-left .update-list').length) {
                        $('.sidebar-top__study-left span').html('<i class="icon-read-blog "></i>' + 'اضافه شده');
                    }
                    /* set heart icon */

                    Swal.fire({
                        position: 'top-end',
                        toast: true,
                        icon: 'success',
                        title: 'با موفقیت انجام شد',
                        showConfirmButton: false,
                        timer: 2500,
                    });
                } else {
                    ele.attr('data-exist', false);
                    ele.removeClass('active');

                    /* .user list update */
                    if ($('.education__favorites .update-list').length) {
                        $('.education__favorites span').html('<i class="icon-ghalbbb "></i>' + 'بعد میخرم');
                    }
                    if ($('.sidebar-top__study-left .update-list').length) {
                        $('.sidebar-top__study-left span').html('<svg xmlns="http://www.w3.org/2000/svg" width="23.712" height="30.776" viewBox="0 0 23.712 30.776"><path id="_102279" data-name="102279" d="M45.272,0h-16.7a2.55,2.55,0,0,0-2.554,2.443v24.7a1.893,1.893,0,0,0,.322,1.1,1.249,1.249,0,0,0,1.031.537,1.862,1.862,0,0,0,1.183-.5l7.47-6.342a1.48,1.48,0,0,1,1.813,0l7.445,6.341a1.8,1.8,0,0,0,1.155.5c.655,0,1.294-.505,1.294-1.635V2.443A2.47,2.47,0,0,0,45.272,0Z" transform="translate(-25.022 1)" fill="none"  stroke-width="2"/></svg>' + 'بعدا میخوانم');
                    }
                    /* set heart icon */
                    Swal.fire({
                        position: 'top-end',
                        toast: true,
                        icon: 'success',
                        title: 'با موفقیت انجام شد',
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            } else {
                Swal.fire({
                    position: 'top-end',
                    toast: true,
                    icon: 'error',
                    title: 'متاسفانه انجام نشد',
                    footer: 'لطفا وارد سایت شوید.',
                    showConfirmButton: false,
                    timer: 2500,
                });
            }
        },
        error: function error() {
            Swal.fire({
                position: 'top-end',
                toast: true,
                icon: 'error',
                title: 'متاسفانه انجام نشد',
                footer: 'در ارسال درخواست مشکلی وجود دارد.',
                showConfirmButton: false,
                timer: 2500,
            });
        },
    });
});

// creat Toast
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

//ajax new adress panel
$('#form-new-address').submit(function (event) {
    event.preventDefault();
    let msg = []
    const name = ($(this).find('#name').val().length) == 0;
    if (name) {
        msg.push("نام نمی تواند خالی باشد")
    }
    const postal_code = ($(this).find('#postal-code').val().length) != 10;
    if (postal_code) {
        msg.push("فرمت کد پستی اشتیاه است ")
    }
    const state = ($(this).find('#state').val().length) == 0;
    if (state) {
        msg.push("استان خالی باشد")
    }
    const phone = ($(this).find('#phone').val().length) != 11;
    if (phone) {
        msg.push("فرمت موبایل اشتیاه است")
    }
    const address = ($(this).find('#address').val().length) == 0;
    if (address) {
        msg.push("ادرس نمی تواند خالی باشد")
    }
    const input = $('#form-new-address input');
    const input_obj = {};
    input.each(function (index, el) {
        const name = $(el).attr('name');
        input_obj[name] = $(el).val();
    });


    if (msg.length == 0) {
        $.ajax({
            url: global_object.ajax_url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'wp_record_address',
                form_data: input_obj,
            },
            success(response) {
                Toast.fire({
                    icon: 'success',
                    title: response.message,
                    title: response.message,
                });
            },
            error(error) {
            },

        });
    } else {
        let html_alert = `<div class="alert_panel">`;
        $.each(msg, function (index, value) {
            html_alert += `<p>${value}<p>`;
            console.log(value)
        })
        html_alert += `</div>`;
        Toast.fire({
            titleText: "موارد زیر برسی شود",
            footer: html_alert,
        });
    }
});
//ajax new adress panel in cart page
$('#cart__sava-addres').click(function (event) {
    event.preventDefault();
    let panelparent = $(this).closest(".panel");
    let msg = []
    const name = (panelparent.find('#name').val().length) == 0;
    if (name) {
        msg.push("نام نمی تواند خالی باشد")
    }
    const postal_code = (panelparent.find('#postal-code').val().length) != 10;
    if (postal_code) {
        msg.push("فرمت کد پستی اشتیاه است ")
    }
    const state = (panelparent.find('#state').val().length) == 0;
    if (state) {
        msg.push("استان خالی باشد")
    }
    const phone = (panelparent.find('#phone').val().length) != 11;
    if (phone) {
        msg.push("فرمت موبایل اشتیاه است")
    }
    const address = (panelparent.find('#address').val().length) == 0;
    if (address) {
        msg.push("ادرس نمی تواند خالی باشد")
    }

    const input = $('#form-new-address input');
    const input_obj = {};
    input.each(function (index, el) {
        const name = $(el).attr('name');
        input_obj[name] = $(el).val();
    });


    if (msg.length == 0) {
        $.ajax({
            url: global_object.ajax_url,
            type: 'POST',
            dataType: 'json',
            data: {
                action: 'wp_record_address',
                form_data: input_obj,
            },
            success(response) {
                Toast.fire({
                    icon: 'success',
                    title: response.message,
                    title: response.message,
                });
                location.href = "?step=2";
            },
            error(error) {
            },

        });
    } else {
        let html_alert = `<div class="alert_panel">`;
        $.each(msg, function (index, value) {
            html_alert += `<p>${value}<p>`;
            console.log(value)
        })
        html_alert += `</div>`;
        Toast.fire({
            titleText: "موارد زیر برسی شود",
            footer: html_alert,
        });
    }
});

//ajax delete adress panel
$('.panel__addresses-item .delete-address').click(function () {
    event.preventDefault();
    const input = $(this).data('delete');
    const del = $(this).parents('.panel__addresses-item');

    $.ajax({
        url: global_object.ajax_url,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'wp_delete_address',
            form_data: input,
        },
        success(response) {
            del.fadeOut('slow');
            Toast.fire({
                icon: 'success',
                title: response.message,
            });
        },
        error(error) {

        },

    });
});

//ajax user update panel
$('#user_update_panel').submit(function (event) {
    event.preventDefault();
    var input = $('#user_update_panel input');
    var input = $('#user_update_panel input');
    const education_panel = $('#education_panel option:selected').val();
    const input_obj = {};
    input.each(function (index, el) {
        const name = $(el).attr('name');
        input_obj[name] = $(el).val();
    });
    $.ajax({
        url: global_object.ajax_url,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'wp_user_update_panel',
            form_data: input_obj,
            education_panel,
        },
        success(response) {
            Toast.fire({
                icon: 'success',
                title: response.message,
            });
        },
        error(error) {

        },

    });
});

//ajax rest panel panel
$('#rest-password-panel').submit(function (event) {
    event.preventDefault();
    const input = $('#rest-password-panel input');
    const input_obj = {};
    input.each(function (index, el) {
        const name = $(el).attr('name');
        input_obj[name] = $(el).val();
    });
    $.ajax({
        url: global_object.ajax_url,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'wp_rest_password_panel',
            form_data: input_obj,
        },
        success(response) {
            Toast.fire({
                icon: 'success',
                title: response.message,
                icon: response.success,
                title: response.message,
            });
        },
        error(error) {

        },

    });
});

//progressbar in index page for rating user
$(document).ready(function () {
    const container = $('.index-about-us__slide-foot');
    container.each(function (e) {
        const rateElm = $(this).find('.index-about-us__slide-rate-inset');
        const rateValue = $(this).children('em').text();
        const convertRate = (rateValue * 100) / 5;
        rateElm.css('width', convertRate + '%');
    });

    //find video duration
    const elmVideo = $('video');
    const videoDuration = elmVideo[0].duration;
    //conver second to HH:MM:s exampel: 62 -> 00:01:02
    const finalTime = new Date(videoDuration * 1000).toISOString().substr(14, 5);
    $('.video-single__overTime').text(finalTime);

    $('div.wpcf7-response-output').ready(function () {
        if ($('div.wpcf7-response-output').css('display', 'block')) {
            $('div.wpcf7-response-output').css('display', 'inline-block');
        }
    });

    $('.index-cat-tabs__tab-ttl').ready(function () {
        const btnMore = $('.index-cat-tabs__tab-title-more');
        const tabTitle = $('.index-cat-tabs__tab-ttl');
        btnMoreShop(tabTitle, btnMore);
    });

    $('.index-cat-tabs__tab-ttl').click(function () {
        const btnMore = $('.index-cat-tabs__tab-title-more');
        const tabTitle = $(this);
        btnMoreShop(tabTitle, btnMore);
    });

    function btnMoreShop(tab, bnt) {
        if (tab.hasClass('active')) {
            const dataTab = tab.attr('data-tab');
            switch (dataTab) {
                case 'latest':
                    bnt.attr('href', '/shop/?sort=new');
                    break;
                case 'bestsellers':
                    bnt.attr('href', '/shop/?sort=topseller');
                    break;
                case 'popular':
                    bnt.attr('href', '/shop/?sort=popular');
                    break;
                default:
                    bnt.attr('href', '/shop/');
            }
        }
    }
});

// $( '.panel__show-detail' ).on( 'click', function() {
// 	$( this ).find( 'i' ).toggleClass( 'rotate' );
// 	let $this = $( this ),
// 		$parent = $this.closest( '.faq-content__content-out-items' ),
// 		$over = $parent.find( '.faq-content__content-out-itm-over' ),
// 		content_height = $parent.find( '.faq-content__content-out-itm-content' ).outerHeight();
//
// 	if ($parent.hasClass( 'active' )) {
// 		$parent.removeClass( 'active' );
// 		$over.removeAttr( 'style' );
// 	} else {
// 		$parent.addClass( 'active' );
// 		$over.css( 'max-height', content_height );
// 	}
// } );

$('.panel__tab-product-table').on('click', function () {
    const $this = $(this);
    const $parent = $this.closest('.panel__tab-product-table');
    const $over = $parent.find('.panel__content-table');
    $over.slideToggle();
});

$(document).ready(function () {
    $('.tab-content  .panel__tab-product-table:first-child .panel__content-table').css('display', 'block');
});

// eslint-disable-next-line no-undef
$('.like-faq-ajax').click(function () {
    const this_faq_tupe = $(this);
    const id = $(this_faq_tupe).data('postid');
    const type = $(this_faq_tupe).data('type');
    console.log(id);

    $.ajax({
        url: global_object.ajax_url,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'wp_like_faq',
            id,
            type,
        },
        success(response) {
            Toast.fire({
                icon: 'success',
                title: response.message,
                icon: response.success,
                title: response.message,
            });
        },
        error(error) {

        },

    });
});

//cart payment radio
$('.cart__payment__all').ready(function () {
    $('.cart__payment__item label').click(function () {
        $('.cart__payment__item').each(function () {
            $(this).find('label').removeClass('checked');
        });

        if ($(this).prop('checked', true)) {
            $(this).parent().find('label').addClass('checked');
        }
    });
});

//cart Transportation radio
$('.cart__content').ready(function () {
    $('.cart__item_shipping_all').click(function () {
        $('.cart__item_shipping_all').each(function () {
            $(this).find('.cart__item_shipping__name').removeClass('checked');
        });

        if ($(this).prop('checked', true)) {
            $(this).find('.cart__item_shipping__name').addClass('checked');
        }
    });
});

$('.panel__add-new-addrs-inputs').ready(function () {
    const lengthinput = $(this).find('.input--panel input');

    $(lengthinput).each(function () {
        if ((this.value).length > 0) {
            $(this).siblings('label').css('top', '0');
        }
    });

    $('.input--panel').children('input').focus(function () {
        $(this).siblings('label').css('top', '0');
    });
});

$('.panel__user-info-inputs').ready(function () {
    const lengthinput = $(this).find('.input-panell input');

    $(lengthinput).each(function () {
        if ((this.value).length > 0) {
            $(this).siblings('label').css('top', '0');
        }
    });

    $('.input-panell').children('input').focus(function () {
        $(this).siblings('label').css('top', '0');
    });
});

$('.input').children('input').focus(function () {
    $(this).siblings('label').css('top', '0');
});

$('.contact-us__inputs').ready(function () {
    const lengthinput = $(this).find('.input input');
    $(lengthinput).each(function () {
        if ((this.value).length > 0) {
            $(this).parents('.input').find('label').css('top', '0');
        }
    });

    $(this).find('.input').click(function () {
        $(this).find('label').css('top', '0');
    });
});

//remove votes single blog
$('.single-blog__rating').ready(function () {
    $(this).find('#votes').remove();
});

$('.post-cart__caption-foot').ready(function () {
    const maines = $('.post-cart__caption-foot');
    $.each(maines, function (key, value) {
        const $length_item = $(this).find('.post-cart__caption-foot-left');
        if ($length_item.length == 0) {
            $(this).addClass('post-cart__caption-foot--def');
        }
    });
});

$('.cart-empty-address__left').click(function () {
    $(this).toggleClass('active');
    let swiperHeght = $('.mySwiper2').height();
    if ($(this).hasClass('active')) {
        $('.mySwiper2').css({'height': swiperHeght + 290});
        // $(this).html('<span> بستن افزودن ، ویرایش آدرس</span><i class="icon-minus"></i>');
    } else {
        $('.mySwiper2').css({'height': swiperHeght - 290});
        // $(this).html('<span>افزودن ، ویرایش آدرس</span><i class="icon-plus"></i>');
    }
    $(this).parents('.cart-empty-address').find('.cart-empty-address__top').css('margin-bottom', '50px');
    $(this).parents('.cart-empty-address').find('.panel').toggle('slow');
});

// add icon to sumbit checout btn in cart
$('#place_order').append('<i class="icon-arrow-left"></i>');

$('.cart-address__content-items').click(function () {
    const itemadress = $(this).find('.cart-address__content-items-title');
    $('.cart-address__content').each(function () {
        $(this).find('.cart-address__content-items-title').removeClass('checked');
    });

    if ($(this).find('input').prop('checked', true)) {
        itemadress.addClass('checked');
    }

    // get info form
    let addr = $(this).children('.cart-address__content-items-title').children('span').text(),
        city = $(this).find('#city').children('span:last-of-type').text(),
        postcode = $(this).find('#postcode').children('span:last-of-type').text(),
        phone_number = $(this).find('#phone_number').children('span:last-of-type').text(),
        full_name = $(this).find('#name').children('span:last-of-type').text();
    full_name = full_name.split(' ');
    let first_name = '',
        last_name = '';
    if (full_name.length > 1) {
        last_name = full_name[full_name.length - 1];
        for (let i = 0; i < full_name.length - 1; i++) {
            first_name += ' ' + full_name[i];
        }
    } else {
        first_name = full_name[0];
        last_name = full_name[0];
    }
    //set info in form
    $('#billing_first_name').val(first_name);
    $('#billing_last_name').val(last_name);
    $('#billing_address_1').val(addr);
    $('#billing_city').val(city);
    $('#billing_postcode').val(postcode);
    $('#billing_phone').val(phone_number);
});

$('#submit_ads_mapp').ready(function () {

    let lat = $('#submit_ads_mapp').attr('data-lat');
    let lang = $('#submit_ads_mapp').attr('data-lang');
    let position = '';
    if (lang !== undefined && lat !== undefined) {
        position = [lat, lang]
    } else {
        position = [32.675081, 51.650543]
    }

    const submit_ads_mapp = L.map('submit_ads_mapp', {scrollWheelZoom: false}).setView(
        position,
        17
    );

    L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
            maxZoom: 25,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
            accessToken:
                'pk.eyJ1Ijoib21vZ2hhZGFzaSIsImEiOiJja3czYnJnemoxYnp4MnVyb29hZHVuY2FtIn0.hloYnAM9Dv_iNANL92yMuA',
        }
    ).addTo(submit_ads_mapp);

    const mapMarker = L.icon({
        iconUrl: window.location.origin + '/wp-content/themes/agri-raz/img/marker1.png',
        iconSize: [24, 29],

    });

    if (lang !== undefined && lat !== undefined) {
        L.marker([lat, lang], {icon: mapMarker}).addTo(submit_ads_mapp);
    }

    var statusMarker = false;

    function onMapClick(e) {
        var marker = L.marker(e.latlng, {
            id: 1,
            icon: mapMarker,
            draggable: "true",
        });
        if (!statusMarker) {
            submit_ads_mapp.addLayer(marker);
            let position = marker.getLatLng();
            $("input#lat").val(position.lat);
            $("input#lng").val(position.lng);
            statusMarker = true;
        }
        marker.on("dragend", function (event) {
            let marker = event.target;
            let position = marker.getLatLng();
            $("input#lat").val(position.lat);
            $("input#lng").val(position.lng);
            marker
                .setLatLng(position, {id: 1, draggable: "true"})
                .bindPopup(position)
                .update();
        });
    }

    submit_ads_mapp.on("click", onMapClick);
});

$('.cart-address__top-action').on('click', function () {
    $(this).toggleClass('active');
    let swiperHeght = $('.mySwiper2').height();
    if ($(this).hasClass('active')) {
        $('.mySwiper2').css({'height': swiperHeght + 290});
        $(this).html('<span> بستن افزودن ، ویرایش آدرس</span><i class="icon-minus"></i>');
    } else {
        $('.mySwiper2').css({'height': swiperHeght - 290});
        $(this).html('<span>افزودن ، ویرایش آدرس</span><i class="icon-plus"></i>');
    }
    $('.cart-address .panel').slideToggle();
});


//disable right click
$(document).bind('cut copy', function (e) {
    e.preventDefault();
});
$(document).ready(function () {
    //غیر فعال کردن برای تمام صفحه
    $("body").on("contextmenu", function (e) {
        return false;
    });

    //غیر فعال کردن بخشی از صفحه
    $("#id").on("contextmenu", function (e) {
        return false;
    });
});